@import "../../../index.scss";

.login_intro_container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .login_intro_wrapper {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .logo_content {
      padding: 1em 0;
      display: flex;
      justify-content: center;
      align-items: center;
      .logo {
        width: 70%;
      }
    }

    .description_text {
      text-align: center;
      padding: 1.5em 0;
      // line-height: 38px;
      font-weight: 400;
    }

    .btn_container {
      width: 80%;
      .btn_wrap {
        width: 100%;
        padding: 1em 0;
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  .login_intro_container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .login_intro_wrapper {
      width: 90%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .logo_content {
        position: absolute;
        top: 15px;
        .logo {
          content: url(../../../assets//icons/logo_white.svg);
          width: 70%;
        }
      }

      .description_text {
        text-align: center;
        padding: 1.5em 0;
        line-height: 30px;
        font-size: 12px;
      }

      .btn_container {
        width: 90%;
        .btn_wrap {
          width: 100%;
          padding: 0.5em 0;
        }
      }
    }
  }
}
