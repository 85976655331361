@import "../../index.scss";

.confirm_pop_container {
  width: 25%;
  height: 20%;
  border-radius: 10px;

  .confirm_pop_wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    .header_content {
    }
    .btn_container {
      width: 100%;
      justify-content: center;
      align-items: center;
      display: flex;
      .btn_wrapper {
        margin-right: 1em;
        width: 120px;
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  .confirm_pop_container {
    width: 80%;
    height: 30%;
  }
}
