@import "../../index.scss";

.subscripe_modal_container {
  width: 100%;
  height: 100vh;

  .subscripe_modal_overlay {
    width: 100%;
    height: 100%;
    display: none;
  }

  .subscripe_modal_content {
    background-color: transparent;
    box-shadow: none;
    margin: 0;
    padding: 0;
    max-width: none;
    width: 100% !important;
    height: 100% !important;

    .subscripe_modal_content_container {
      width: 100%;
      height: calc(100% - 10px);
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      .subscripe_background {
        width: 100%;
        height: 100%;
      }

      // header logo
      .subscripe_header_content {
        position: absolute;
        width: 100%;
        top: 0px;
        .subscripe_header_wrapper {
          display: flex;
          justify-content: space-between;
          padding: 1em 1.5em;
          align-items: center;
          .subscripe_header_image {
            width: 8%;
          }
        }
      }

      // subscripe body
      .subscripe_body_content {
        position: absolute;
        text-align: center;
        width: 30%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .body_head_text {
          color: $btnTextColor;
          font-family: "Orbitron";
          .top_head {
            font-size: 44px;
            font-weight: 700;
          }
          .middle_head {
            padding: 0.5em 0;
            font-size: 30px;
            font-weight: 700;
          }
        }
        .body_description_text {
          width: 80%;
          font-size: 20px;
          color: #edb9ff;
          padding: 1em 0;
        }

        .body_btn_content {
          padding: 2em 0;
          height: 54px;
        }
      }
    }

    .border_bottom {
      width: 100%;
      height: 10px;
      background: $btnBackground;
      -webkit-box-shadow: 10px -16px 131px 28px rgba(0, 134, 255, 1);
      -moz-box-shadow: 10px -16px 131px 28px rgba(0, 134, 255, 1);
      box-shadow: 10px -16px 131px 28px rgba(0, 134, 255, 1);
    }
  }
}

@media only screen and (max-width: 1000px) {
  .subscripe_modal_container {
    .subscripe_modal_content {
      .subscripe_modal_content_container {
        .subscripe_background {
          content: url(../../assets/images/mobile_bg.png);
        }

        // header
        .subscripe_header_content {
          .subscripe_header_wrapper {
            .subscripe_header_image {
              width: 30% !important;
            }
          }
        }

        // subscripe body
        .subscripe_body_content {
          width: 90%;
          top: 15%;

          .body_head_text {
            .top_head {
              font-size: 38px;
            }
            .middle_head {
              font-size: 20px;
            }
          }
          .body_description_text {
            font-size: 16px;
            padding: 0.5em 0;
          }

          .body_btn_content {
            padding: 1em 0;
            .primary_button {
              padding: 0 2em !important;
              height: 50px;
            }
          }
        }
      }
    }
  }
}
