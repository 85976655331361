@import "../../index.scss";

.chat_component {
  width: 100%;
  height: 80%;
  background-color: none;
  opacity: 1;
  transform: translateY(0);
  transition: 0.5s ease;
  .chat_container {
    width: 100%;
    height: 100%;
    position: relative;
    .chat_wrapper {
      width: 100%;
      height: 100%;

      .chat_header_content {
        width: 100%;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        .header_text {
          color: $btnTextColor;
        }
      }

      .chat_body_content {
        width: 100%;
        height: calc(100% - 60px);
        position: relative;

        .chat_message_content {
          // width: 100%;
          // height: calc(100% - 60px);
          width: 95%;
          height: calc(100% - 60px);
          overflow-y: scroll;
          background: #53535363;
          border-radius: 10px;
          overflow-y: scroll;
        }

        /* width */
        .chat_message_content::-webkit-scrollbar {
          width: 3px;
        }

        /* Track */
        .chat_message_content::-webkit-scrollbar-track {
          background: none;
        }

        /* Handle */
        .chat_message_content::-webkit-scrollbar-thumb {
          background: rgb(51, 0, 255);
          background: linear-gradient(
            180deg,
            rgba(51, 0, 255, 1) 0%,
            rgba(78, 232, 255, 1) 100%
          );
        }

        /* Handle on hover */
        .chat_message_content::-webkit-scrollbar-thumb:hover {
          background: $hyberLink;
        }

        .chat_input_container {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 60px;

          .chat_input_content_wrapper {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            .message_input {
              position: relative;
              width: 90%;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              .message_send_icon_container {
                position: absolute;
                right: 0;
                width: 45px;
                height: 45px;
                top: 8px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                .send_message_icon {
                  width: 20px;
                  height: 20px;
                }
              }
            }
          }
        }

        // loader
        .spinner {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          .chat_loader {
            width: 120px;
            height: 120px;
          }
        }
      }
    }

    // Close Button
    .chat_clost_btn_content {
      position: absolute;
      right: 20px;
      top: 5px;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background: $btnBackground;
      cursor: pointer;
      .close_btn_wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .close_btn_icon {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  .chat_component {
    background-color: none;
    height: 90%;
    .chat_container {
      .chat_wrapper {
        .chat_header_content {
          display: none;
        }
        .chat_body_content {
          height: 100%;
          // padding-top: 0.5em;

          .chat_message_content {
            width: 100%;
            height: calc(100% - 60px - 0.5em);
            overflow-y: scroll;
          }

          // loader
        .spinner {

          .chat_loader {
            width: 80px;
            height: 80px;
          }
        }
        }
      }

      // Close Button
      .chat_clost_btn_content {
        width: 35px;
        height: 35px;
        .close_btn_wrapper {
          .close_btn_icon {
            width: 15px;
            height: 15px;
          }
        }
      }
    }
  }
}
