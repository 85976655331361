@import "../../index.scss";

.player_error_component {
  width: 100%;
  height: 100%;
  .player_error_wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .error_message {
      padding: 0 1em;
    }
    .btn_container {
      padding: 1em 0;
    }
  }
}
