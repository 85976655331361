@import "../../../index.scss";

.input_container {
  width: 100%;

  .field_wrapper {
    width: 100%;
    .input_field {
      width: -webkit-fill-available;
      width: -moz-available;
      height: 45px;
      padding: 0 1em;
      border: 1px solid $inputBorder;
      font-size: 15px;
      line-height: 1.2;
      color: #333;
      font-family: "Poppins";
      display: block;
      background: #fff;
      border-radius: 6px;
      outline: none;
    }
  }
}
