@import "../../index.scss";

.auth_template_component {
  width: 100%;
  height: 100vh;
  .auth_template_container {
    width: 100%;
    height: 100%;
    .auth_template_wrapper {
      width: 100%;
      height: 100%;
      background-color: black;
      display: flex;
      flex-wrap: wrap;

      .auth_template_content_left {
        width: 60%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .auth_template_player_image {
          width: 80%;
        }

        .auth_template_player_video{
          height: inherit;
          border: none;
          z-index: 2;
          width: 100%;
          height: 100%;
          background: black;
          border-radius: 10px;
        }

        .auth_template_player_video_wrapper{
          position: absolute;
          height: inherit;
          border: none;
          z-index: 2;
          width: 49%;
          height: 54%;
          padding: 3px;
          background: linear-gradient(90deg, rgba(0,184,170,1) 0%, rgba(135,108,250,1) 21%, rgba(202,3,194,1) 100%, rgba(0,139,240,1) 100%);
          border-radius: 10px;
          box-shadow: rgba(45,56,72,0.8) 0px 0px 0px 14px;
        }

        .auth_template_player_background_video{
          z-index: 1;
          width: 60%;
          position: absolute;
          box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.5);
        }

        #background-video{
          width: 100%;
          height: 100%;
          object-fit: fill;
        }
        
      }
      .auth_template_content_right {
        width: 40%;
        height: 100%;
        .auth_template_right_wrapper {
          background: $formBg;
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          // Header
          .auth_template_header_content {
            width: 100%;
            height: 100px;
            .auth_template_header_content_wrapper {
              height: 100%;
              padding-right: 1.5em;
              display: flex;
              justify-content: flex-end;
              align-items: center;
              .nav_btn_content {
                padding: 0 0.5em;
              }
            }
          }

          .auth_template_body_content {
            width: 100%;
            height: calc(100% - 100px);
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            //Footer
            .auth_template_footer_content {
              width: 100%;
              height: 90px;
              .auth_template_footer_content_wrapper {
                width: 100%;
                height: 100%;
                .auth_footer_text {
                  height: 24px;
                  width: 100%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  background: $btnBackground;
                  color: $btnTextColor;
                  font-weight: 600;
                  font-size: 14px;
                }
                .auth_footer_partners_wrapper {
                  width: 100%;
                  padding: 0.5em 0;
                  display: flex;
                  align-items: center;
                  justify-content: space-around;
                  .footer_partner_content {
                    width: 25%;
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .partner_logo {
                      width: 70%;
                      height: 50px;
                    }
                  }
                  .footer_partner_content::after {
                    content: "";
                    position: absolute;
                    border-right: 2px $divider solid;
                    height: 20px;
                    right: 0;
                  }
                }
              }
            }
            .auth_template_form_content {
              width: 100%;
              height: calc(100% - 90px);
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  .auth_template_component {
    .auth_template_container {
      .auth_template_wrapper {
        display: flex;
        justify-content: center;
        position: relative;
        align-items: center;
        .auth_template_content_left {
          display: none;
        }

        .auth_template_content_right {
          width: 100%;
          height: 65%;
          display: flex;
          justify-content: center;
          align-items: center;
          .auth_template_right_wrapper {
            border-radius: 8px;
            width: 85%;

            // Header
            .auth_template_header_content {
              position: absolute;
              bottom: 0;
              left: 0;
              .auth_template_header_content_wrapper {
                padding: 0;
                justify-content: center;
                .nav_btn_content {
                  .nav_btn_ui_container {
                    background-color: $movileNavBtnColor;
                    border: 1px solid $movileNavBtnColor;
                  }
                }
              }
            }

            .auth_template_body_content {
              height: 100%;
              width: 100%;

              //Footer
              .auth_template_footer_content {
                height: 70px;
                .auth_template_footer_content_wrapper {
                  .auth_footer_text {
                  }
                  .auth_footer_partners_wrapper {
                    padding: 0;
                    .footer_partner_content {
                      .partner_logo {
                      }
                    }
                  }
                }
              }

              //Body
              .auth_template_form_content {
                height: calc(100% - 70px);
              }
            }
          }
        }
      }
    }
  }
}
