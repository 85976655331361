@import "../../../index.scss";

.primary_button {
  background: $btnBackground;
  color: $btnTextColor;
  width: 100%;
  height: 45px;
  border: none;
  border-radius: 10px;
  font-size: 16px;
  text-transform: uppercase;
  cursor: pointer;
  font-weight: 700;
  user-select: none;
}

.btn_loading {
  pointer-events: none;
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  .btn_loading_icon {
    width: 50px;
    height: 50px;
  }
}
