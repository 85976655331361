@import "../../../index.scss";

.nav_btn_ui_container {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background-color: $btnTextColor;
  cursor: pointer;
  user-select: none;
  .nav_btn_ui_wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .nav_btn_icon {
      width: 20px;
      height: 20px;
    }
  }
}
