@import "../../index.scss";

.message_componet {
  width: 100%;
  .message_container {
    width: 80%;
    .message_wrapper {
      width: 100%;
      display: flex;
      padding: 0.5em 1em;
      margin-bottom: 0.5em;
      .message_profile_content {
        width: 50px;
        height: 50px;
        .profile_image {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
        .message_profile_picture {
          width: 50px;
          height: 50px;
          // background-color: $hyberLink;
          color: $btnTextColor;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1.9em;
          text-transform: uppercase;
          border-radius: 50%;
        }
      }
      .message_content {
        color: $btnTextColor;
        padding-left: 1em;
        .message_details {
          overflow-wrap: break-word;
          word-wrap: break-word;

          -ms-word-break: break-all;
          /* This is the dangerous one in WebKit, as it breaks things wherever */
          word-break: break-all;
          /* Instead use this non-standard one: */
          word-break: break-word;

          /* Adds a hyphen where the word breaks, if supported (No Blink) */
          -ms-hyphens: auto;
          -moz-hyphens: auto;
          -webkit-hyphens: auto;
          hyphens: auto;
        }
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  .message_componet {
    .message_container {
      .message_wrapper {
        .message_profile_content {
          width: 45px;
          height: 45px;
          .message_profile_picture {
            width: 45px;
            height: 45px;
            font-size: 1.5em;
          }
        }
      }
    }
  }
}
