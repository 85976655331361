$primaryColor: #000000;
$primaryBackground: #000000;
$btnTextColor: #ffffff;
$formBg: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.5) 0%,
    rgba(255, 255, 255, 0.2) 100%
  ),
  linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9));
$btnBackground: linear-gradient(90deg, #3300ff 0%, #4ee8ff 100%);
$divider: rgba(0, 0, 0, 0.1);
$movileNavBtnColor: rgba(255, 255, 255, 0.1);
$googleBtn: linear-gradient(180deg, #4285f4 0%, #255db9 100%);
$dividerColor: rgba(0, 0, 0, 0.7);
$inputBorder: rgba(0, 0, 0, 0.2);
$hyberLink: rgba(51, 0, 255, 1);
$walletBtn: rgba(0, 0, 0, 0.05);
$error: rgba(255, 71, 71, 1);
$headerBg: linear-gradient(76.67% 76.67% at 50% 50%, #13486f 0%, #000000 100%);

@font-face {
  font-family: "Poppins";
  font-weight: 900;
  font-style: normal;
  src: local("Montserrat"),
    url(./assets//fonts/Poppins/Poppins-Black.ttf) format("opentype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 800;
  font-style: normal;
  src: local("Montserrat"),
    url(./assets/fonts/Poppins/Poppins-ExtraBold.ttf) format("opentype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 700;
  font-style: normal;
  src: local("Montserrat"),
    url(./assets/fonts/Poppins/Poppins-Bold.ttf) format("opentype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 600;
  font-style: normal;
  src: local("Montserrat"),
    url(./assets/fonts/Poppins/Poppins-Medium.ttf) format("opentype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 500;
  font-style: normal;
  src: local("Montserrat"),
    url(./assets/fonts/Poppins/Poppins-SemiBold.ttf) format("opentype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 400;
  font-style: normal;
  src: local("Montserrat"),
    url(./assets/fonts/Poppins/Poppins-Regular.ttf) format("opentype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 300;
  font-style: normal;
  src: local("Montserrat"),
    url(./assets/fonts/Poppins/Poppins-Light.ttf) format("opentype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 200;
  font-style: normal;
  src: local("Montserrat"),
    url(./assets/fonts/Poppins/Poppins-ExtraLight.ttf) format("opentype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 100;
  font-style: normal;
  src: local("Montserrat"),
    url(./assets/fonts/Poppins/Poppins-Thin.ttf) format("opentype");
}

@font-face {
  font-family: "Orbitron";
  font-weight: 700;
  font-style: normal;
  src: local("Montserrat"),
    url(./assets/fonts/Orbitron/static/Orbitron-Bold.ttf) format("opentype");
}

@font-face {
  font-family: "Orbitron";
  font-weight: 600;
  font-style: normal;
  src: local("Montserrat"),
    url(./assets/fonts/Orbitron/static/Orbitron-SemiBold.ttf) format("opentype");
}

@font-face {
  font-family: "Orbitron";
  font-weight: 500;
  font-style: normal;
  src: local("Montserrat"),
    url(./assets/fonts/Orbitron/static/Orbitron-Regular.ttf) format("opentype");
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Poppins", sans-serif;
}

.jw-logo-button {
  display: none !important;
}

.head1 {
  font-size: 32px;
  font-weight: bold;
}
.head2 {
  font-weight: bold;
  font-size: 24px;
}
.head3 {
  font-weight: bold;
  font-size: 18.72px;
}
.head4 {
  font-weight: bold;
  font-size: 16px;
}
.head5 {
  font-weight: bold;
  font-size: 13.28px;
}
.head6 {
  font-weight: bold;
  font-size: 12px;
}

.para1 {
  font-size: 32px;
  font-style: normal;
}
.para2 {
  font-style: normal;
  font-size: 24px;
}
.para3 {
  font-style: normal;
  font-size: 18.72px;
}
.para4 {
  font-style: normal;
  font-size: 16px;
}
.para5 {
  font-style: normal;
  font-size: 13.28px;
}
.para6 {
  font-style: normal;
  font-size: 12px;
}

.weight_500 {
  font-weight: 500;
}

.font_20 {
  font-size: 20px;
}

.strike {
  display: block !important;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
}

.strike > span {
  position: relative;
  display: inline-block;
}

.strike > span:before,
.strike > span:after {
  content: "";
  position: absolute;
  top: 50%;
  width: 9999px;
  height: 1px;
  background: $dividerColor;
}

.strike > span:before {
  right: 100%;
  margin-right: 15px;
}

.strike > span:after {
  left: 100%;
  margin-left: 15px;
}

.secondary_text {
  color: $dividerColor;
}

.hyber_link {
  color: $hyberLink;
}

.form_error {
  color: red;
  font-size: 14px;
  font-weight: 400;
}

.text_center {
  text-align: center;
}

.padding_5 {
  padding: 0.5em 0;
}

.white_text {
  color: $btnTextColor;
}
